import { ArrowUpOutlined, FilterOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Dropdown, Pagination, Row, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { ifaceContractMarketplace } from '~/blockchain/contract'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import {
  checkTransactionConfirmation,
  hexToNumberString,
} from '~/blockchain/provider'
import {
  GENESIS_SORT_SALE_USER,
  GENESIS_SORT_SALE_USER_JA,
  SCREEN_SIZE,
} from '~/common/constants'
// import { useQueryState } from '~/common/hooks/useQueryState'
import CancelSell from '~/components/Modals/CancelSell'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import { getSpeciesValue } from '~/helpers/Species'
import { useCommon } from '~/views/redux/hooks/useCommon'
import configs from '~/configs'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import { NodataMonster } from '~/components/shared-components/NoData/NodataMonster'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import BodyProfile from '~/components/shared-components/BodyProfile'
import { useTranslation } from '~/common/hooks/useTranslation'
import { FilterHashNFT } from '~/views/app/HashNFT/components/FilterHashNFT'
import { HashCard } from '~/components/shared-components/Card/HashCard'
import { useMonster } from '~/views/redux/hooks/useMonster'
import { getNameItem, getNameItemByContract } from '~/helpers/ItemName'
import { scrollToTop } from '~/helpers/common'
import { useMediaQuery } from 'react-responsive'
import getImageHash from '~/assets/data/ImageHash.json'
import { useSDK } from '@metamask/sdk-react'
export const HashSale = () => {
  const { t } = useTranslation()
  const { sdk } = useSDK()
  const [mainSeedFilter, setMainSeedFilter] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyCreated'))?.mainSeedFilter || [],
  )

  const [category, setCategory] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyCreated'))?.category || [],
  )
  const [regenerationCount, setRegenerationCount] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyCreated'))?.regenerationCount || [
      0, 5,
    ],
  )
  const [pageSize, setPageSize] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyCreated'))?.pageSize || 10,
  )

  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyCreated'))?.page || 1,
  )
  const [sort, setSort] = useState(GENESIS_SORT_SALE_USER[0])
  const [typeSort, setTypeSort] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyCreated'))?.typeSort || '-1',
  )
  const [txHash, setTxHash] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoadingModal, setLoadingModal] = useState(false)
  const [nftActive, setNftActive] = useState()
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [openFilterHash, setOpenFilterHash] = useState(false)
  const {
    data: { user },
  } = useTheme()
  const {
    actions,
    data: { allHashes, isLoading, totalHash },
  } = useMonster()
  const {
    actions: actionsTema,
    data: { allTEMAs },
  } = useCommon()

  // const { page, pageSize, setPage, setPageSize } = useQueryState()

  const handleChangeSort = (e) => {
    setSort(GENESIS_SORT_SALE_USER[e.key])
  }
  const onChangePage = (page) => {
    setPage(page)
  }
  const onChangePageSize = (current, size) => {
    setPageSize(size)
  }
  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }
  const showDrawer = () => {
    setOpenFilterHash(true)
  }

  const onClose = () => {
    setOpenFilterHash(false)
  }

  //Refresh data
  const refreshData = useCallback(() => {
    const params = {
      page,
      pageSize: pageSize,
      wallet_address: user.address_wallet,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      category: category?.map((data) => data?.name),
      main_seed: mainSeedFilter,
      regenerationCount: {
        from: regenerationCount[0],
        to: regenerationCount[1],
      },
    }
    actions.getAllMonsterHash(params, () => {
      scrollToTop()
    })
  }, [
    page,
    pageSize,
    sort,
    typeSort,
    category,
    mainSeedFilter,
    regenerationCount,
  ])

  useEffect(() => {
    if (user) {
      refreshData()
      actionsTema.getTema()
    }
  }, [
    txHash,
    page,
    pageSize,
    sort,
    typeSort,
    category,
    mainSeedFilter,
    regenerationCount,
  ])
  useEffect(() => {
    if (allHashes?.length === 0 && page > 1) {
      onChangePage(1)
    }
  }, [
    txHash,
    allHashes,
    pageSize,
    sort,
    typeSort,
    category,
    mainSeedFilter,
    regenerationCount,
  ])

  useEffect(() => {
    actionsTema.getTema()
  }, [])
  const pageFilter = JSON.parse(localStorage.getItem('hashNFTMyCreated'))?.page
  useEffect(() => {
    if (pageFilter > 1) {
      setPage(pageFilter)
    }
  }, [pageFilter])
  const showModalCancelSell = () => {
    setIsModalOpen(true)
  }

  const showModalSuccess = () => {
    setIsModalSuccessOpen(true)
  }

  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSuccess = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setLoadingModal(false)
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [nftActive?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccess()
          }
        })
      } else {
        await changeNetworkInMetamask(configs.NETWORK.chainId, setLoadingModal)
        let networkId = await window.ethereum.request({
          method: 'eth_chainId',
        })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        window.ethereum
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [nftActive?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccess()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }
  const clearAll = () => {
    setMainSeedFilter([])
    setCategory([])
    setRegenerationCount([])
  }
  function getItemGraphic(itemId) {
    // Kiểm tra điều kiện đặc biệt trước khi xử lý
    if (itemId === 'UPA 1912') {
      // eslint-disable-next-line
      itemId = 'UPA_1912'
    }
    if (itemId === 'UPA Gorem') {
      // eslint-disable-next-line
      itemId = 'UPA_Gorem'
    }
    if (itemId === 'UPA AC') {
      // eslint-disable-next-line
      itemId = 'UPA_AC'
    }
    if (itemId === 'UPA ODEN') {
      // eslint-disable-next-line
      itemId = 'UPA_ODEN'
    }
    if (itemId === 'UPA Ossan') {
      // eslint-disable-next-line
      itemId = 'UPA_Ossan'
    }
    if (itemId === 'UPA Villain') {
      // eslint-disable-next-line
      itemId = 'UPA_Villain'
    }
    if (itemId === 'Yamato T Shirt') {
      // eslint-disable-next-line
      itemId = 'YamatoT_Shirt'
    }
    if (itemId === 'Sound Horse') {
      // eslint-disable-next-line
      itemId = 'Soundhorse'
    }

    // Loại bỏ khoảng trắng
    let formattedItemId = itemId?.replace(/\s+/g, '')

    for (let i = 0; i < getImageHash.length; i++) {
      let formattedImageHashItemId = getImageHash[i].itemId.replace(/\s+/g, '')

      if (formattedImageHashItemId === formattedItemId) {
        return getImageHash[i].itemGraphic
      }
    }
    return null
  }
  return (
    <BodyProfile>
      <Row className={`${isMobile ? '' : 'mx-auto h-[100vh]'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterHashNFT
                page={page}
                typeSort={typeSort}
                pageSize={pageSize}
                mainSeedFilter={mainSeedFilter}
                category={category}
                regenerationCount={regenerationCount}
                setMainSeedFilter={setMainSeedFilter}
                setCategory={setCategory}
                setRegenerationCount={setRegenerationCount}
                classCustom="filter__profile"
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="mx-auto z-10 pl-0 pt-[1.5rem] relative md:sticky top-0 sm:top-[104px] pb-12">
            <div className="text-[24px] mb-4 uppercase">
              {t('profile.menu.hash')}
            </div>
            <div className="flex justify-between flex-wrap mb-4">
              <div className="text-[24px]">
                {t('countHash', {
                  total: totalHash,
                })}
                {totalHash > 1 ? t('es') : ''}
              </div>
              {isMobile ? (
                <div className="flex items-center text-[16px] gap-2">
                  <Dropdown
                    className="flex items-center cursor-pointer sort__value"
                    menu={{
                      items:
                        user?.player_language === 'en'
                          ? GENESIS_SORT_SALE_USER
                          : GENESIS_SORT_SALE_USER_JA,
                      selectable: true,
                      onClick: handleChangeSort,
                    }}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                      <div className="title">
                        {' '}
                        {t(`common.filter.sort.${sort?.valueLanguage}`)}
                      </div>
                    </Button>
                  </Dropdown>

                  <Button
                    className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                    onClick={() => {
                      handleChangeTypeSort()
                    }}
                  >
                    <ArrowUpOutlined
                      style={{ fontSize: 18 }}
                      rotate={typeSort === '1' ? 0 : 180}
                    />
                  </Button>

                  <div>
                    <Button
                      type="primary"
                      onClick={showDrawer}
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                    >
                      <FilterOutlined />
                      {t('filter')}
                    </Button>
                    <Drawer
                      title={t('filter')}
                      width={330}
                      placement="left"
                      onClose={onClose}
                      open={openFilterHash}
                      extra={
                        <div
                          className="text-[16px] text-[#FAAD14] cursor-pointer"
                          onClick={() => {
                            clearAll()
                          }}
                        >
                          {t('clearAll')}
                        </div>
                      }
                    >
                      <div className="flex w-full h-full">
                        <FilterHashNFT
                          typeSort={typeSort}
                          page={page}
                          pageSize={pageSize}
                          mainSeedFilter={mainSeedFilter}
                          category={category}
                          regenerationCount={regenerationCount}
                          setMainSeedFilter={setMainSeedFilter}
                          setCategory={setCategory}
                          setRegenerationCount={setRegenerationCount}
                          classCustom="filter__profile"
                        />
                      </div>
                    </Drawer>
                  </div>
                </div>
              ) : (
                <div className="flex items-center text-[16px] gap-2">
                  <div> {t('sortBy')}</div>

                  <Dropdown
                    className="flex items-center cursor-pointer ml-3  sort__value"
                    menu={{
                      items:
                        user?.player_language === 'en'
                          ? GENESIS_SORT_SALE_USER
                          : GENESIS_SORT_SALE_USER_JA,
                      selectable: true,
                      onClick: handleChangeSort,
                    }}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                      <div className="title">
                        {' '}
                        {t(`common.filter.sort.${sort?.valueLanguage}`)}
                      </div>
                    </Button>
                  </Dropdown>

                  <Button
                    className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                    onClick={() => {
                      handleChangeTypeSort()
                    }}
                  >
                    <ArrowUpOutlined
                      style={{ fontSize: 18 }}
                      rotate={typeSort === '1' ? 0 : 180}
                    />
                  </Button>
                </div>
              )}
            </div>
            <Spin spinning={isLoading}>
              <div className="flex flex-wrap justify-center gap-4">
                {allHashes?.map((data, i) => {
                  let image
                  switch (data?.type_name) {
                    case 'Genesis Hash':
                    case 'General Hash':
                      image = getSpeciesValue(data?.group, data?.species)?.image
                        ? `${
                            process.env.REACT_APP_BASE_URL_IMAGE
                          }/public/image/image/hash/${
                            getSpeciesValue(data?.group, data?.species)?.image
                          }.png`
                        : '../imgs/hash/golem.png'
                      break
                    case 'Hash Chip NFT':
                      image = '../imgs/common/result.png'
                      break
                    default:
                      image =
                        data?.type_name === 'Regeneration Hash' &&
                        getNameItem(3, data?.tokenId)?.image
                          ? `${
                              process.env.REACT_APP_BASE_URL_IMAGE
                            }/public/image/image/items/${
                              getNameItem(3, data?.tokenId)?.image
                            }.png`
                          : `${
                              process.env.REACT_APP_BASE_URL_IMAGE
                            }/public/image/image/hash/${getItemGraphic(
                              data?.main_seed,
                            )}`
                      break
                  }
                  return (
                    <HashCard
                      t={t}
                      key={i?.toString()}
                      img={image}
                      data={{
                        ...data,
                        type: getSpeciesValue(data?.group, data?.species)?.name,
                      }}
                      button={{
                        title: 'Cancel',
                        functionButton: () => {
                          setNftActive(data)
                          showModalCancelSell()
                        },
                      }}
                      tema={allTEMAs}
                    />
                  )
                })}
              </div>
            </Spin>

            {!isLoading && totalHash === 0 && <NodataMonster />}

            {totalHash > 0 && (
              <div className="pagination__common">
                <Pagination
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                  className="mt-5"
                  size="small"
                  locale={{ jump_to: 'Go to', page: '' }}
                  total={totalHash}
                  // defaultCurrent={page}
                  pageSize={pageSize}
                  current={page}
                  showSizeChanger
                  showQuickJumper
                  showTitle
                />
              </div>
            )}

            <CancelSell
              action={{
                isModalOpen: isModalOpen,
                handleOk: handleCancelSell,
                handleCancel: handleCancel,
              }}
              t={t}
              name={
                (nftActive?.type_name === 'Genesis Hash' ||
                nftActive?.type_name === 'General Hash'
                  ? `${nftActive?.main_seed} (#${nftActive?.tokenId})`
                  : getNameItemByContract(
                      nftActive?.contractAddress,
                      nftActive?.tokenId,
                    )?.name || nftActive?.main_seed) || nftActive?.name
              }
              loadingModal={isLoadingModal}
            />

            <TransactionCompletedCancelSell
              action={{
                isModalOpen: isModalSuccessOpen,
                handleOk: handleShare,
                handleCancel: handleCancelSuccess,
              }}
              t={t}
              name={
                (nftActive?.type_name === 'Genesis Hash' ||
                nftActive?.type_name === 'General Hash'
                  ? `${nftActive?.main_seed} (#${nftActive?.tokenId})`
                  : getNameItemByContract(
                      nftActive?.contractAddress,
                      nftActive?.tokenId,
                    )?.name || nftActive?.main_seed) || nftActive?.name
              }
              store={'Marketplace'}
              txHash={txHash}
            />
          </div>
        </Col>
      </Row>
    </BodyProfile>
  )
}
export default HashSale
