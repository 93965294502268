import configs from '~/configs'
import { Card, Col, Row } from 'antd'

export const InfoHash = (props) => {
  const { t, monsterDetail } = props
  return (
    <Card className="w-full">
      {monsterDetail?.type_name === 'Hash Chip NFT' ? (
        <>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="grid grid-cols-1 bg-[#020f38] py-[16px] px-[24px] min-h-[142px] rounded-[8px] font__M__plus font-bold">
              <Row>
                <Col span={12} className="text-[16px]">
                  <div className="mb-3 font__M__plus font-bold">
                    {t('hashNFTDetail.ID')}
                  </div>
                  {monsterDetail?.main_seed && (
                    <div className="mb-3 font__M__plus font-bold">
                      {t('hashNFTDetail.name')}
                    </div>
                  )}
                  {monsterDetail?.name &&
                    monsterDetail?.asset === 'HASH CHIP NFT' && (
                      <div className="mb-3 font__M__plus font-bold">
                        {t('hashNFTDetail.name')}
                      </div>
                    )}

                  {monsterDetail?.description && (
                    <div className="mb-3 font__M__plus font-bold">
                      {t('hashNFTDetail.description')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  <div className="mb-3 font__M__plus font-medium">
                    <a
                      style={{ color: '#40A9FF' }}
                      href={`${configs.NETWORK.scan}token/${
                        monsterDetail?.contractAddress ??
                        configs.ADDRESS_HASHCHIP_OAS
                      }/instance/${monsterDetail?.tokenId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {monsterDetail?.tokenId}
                    </a>
                  </div>
                  {monsterDetail?.main_seed && (
                    <div className="mb-3 font__M__plus font-medium">
                      {monsterDetail?.type_name} [{monsterDetail?.main_seed}]
                    </div>
                  )}
                  {monsterDetail?.name &&
                    monsterDetail?.asset === 'HASH CHIP NFT' && (
                      <div className="mb-3 font__M__plus font-medium">
                        {monsterDetail?.name}
                      </div>
                    )}
                  {monsterDetail?.description && (
                    <div
                      className="mb-3 font__M__plus font-medium"
                      dangerouslySetInnerHTML={{
                        __html: monsterDetail.description,
                      }}
                    ></div>
                  )}
                </Col>
              </Row>
              {/* {monsterDetail?.timesRegeneration !== undefined && (
                <Row>
                  <Col span={12} className="text-[16px]">
                    <div className="mb-3 font__M__plus font-bold">
                      {t('hashNFTDetail.regenerationCount')}
                    </div>
                  </Col>
                  <Col span={12} className="text-end">
                    <div className="mb-3 font__M__plus font-medium">
                      {monsterDetail?.type_name === 'Genesis Hash'
                        ? monsterDetail?.timesRegeneration + '/5'
                        : monsterDetail?.type_name === 'Hash Chip NFT'
                        ? monsterDetail?.timesRegeneration + '/1'
                        : monsterDetail?.timesRegeneration + '/3'}
                    </div>
                  </Col>
                </Row>
              )}
              {monsterDetail?.timesRegeneration === undefined && (
                <Row>
                  <Col span={12} className="text-[16px]">
                    <div className="mb-3 font__M__plus font-bold">
                      {t('hashNFTDetail.regenerationCount')}
                    </div>
                  </Col>
                  <Col span={12} className="text-end">
                    <div className="mb-3 font__M__plus font-medium">0/1</div>
                  </Col>
                </Row>
              )} */}
              <Row>
                <Col span={12} className="text-[16px]">
                  {monsterDetail?.main_seed && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.mainSeed')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.main_seed && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.main_seed}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12} className=" mb-3text-[16px]">
                  {monsterDetail?.mint && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.mint')}
                    </div>
                  )}
                </Col>
                <Col span={12} className=" mb-3 text-end">
                  {monsterDetail?.mint && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.mint}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.monster_rank_e && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.monster_rank_e')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.monster_rank_e && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.monster_rank_e}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.monster_rank_d && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.monster_rank_d')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.monster_rank_d && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.monster_rank_d}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.monster_rank_c && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.monster_rank_c')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.monster_rank_c && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.monster_rank_c}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.monster_rank_b && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.monster_rank_b')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.monster_rank_b && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.monster_rank_b}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.monster_rank_a && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.monster_rank_a')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.monster_rank_a && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.monster_rank_a}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.monster_regeneration_1 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.monster_regeneration_1')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.monster_regeneration_1 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.monster_regeneration_1}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.monster_regeneration_2 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.monster_regeneration_2')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.monster_regeneration_2 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.monster_regeneration_2}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.monster_regeneration_3 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.monster_regeneration_3')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.monster_regeneration_3 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.monster_regeneration_3}
                    </div>
                  )}
                </Col>
              </Row>{' '}
            </div>
            <div className="bg-[#020f38] py-[16px] px-[24px] min-h-[142px] rounded-[8px]">
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.monster_fusion_1 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.monster_fusion_1')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.monster_fusion_1 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.monster_fusion_1}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.monster_fusion_2 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.monster_fusion_2')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.monster_fusion_2 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.monster_fusion_2}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.coach_1 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.coach_1')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.coach_1 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.coach_1}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.coach_2 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.coach_2')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.coach_2 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.coach_2}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.crystallization_1 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.crystallization_1')}
                    </div>
                  )}
                </Col>
                <Col span={12} className="text-end">
                  {monsterDetail?.crystallization_1 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.crystallization_1}
                    </div>
                  )}
                </Col>
              </Row>{' '}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.crystallization_2')}
                    </div>
                  )}
                </Col>
                <Col span={12} className=" mb-3 text-end">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.crystallization_2}
                    </div>
                  )}
                </Col>
              </Row>
              {/* // */}
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.ranked_match_1')}
                    </div>
                  )}
                </Col>
                <Col span={12} className=" mb-3 text-end">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.ranked_match_1}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.ranked_match_5')}
                    </div>
                  )}
                </Col>
                <Col span={12} className=" mb-3 text-end">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.ranked_match_5}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.ranked_match_10')}
                    </div>
                  )}
                </Col>
                <Col span={12} className=" mb-3 text-end">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.ranked_match_10}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.exploration_1')}
                    </div>
                  )}
                </Col>
                <Col span={12} className=" mb-3 text-end">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.exploration_1}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.exploration_5')}
                    </div>
                  )}
                </Col>
                <Col span={12} className=" mb-3 text-end">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.exploration_5}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12} className=" mb-3 text-[16px]">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-bold">
                      {t('hashNFTDetail.exploration_10')}
                    </div>
                  )}
                </Col>
                <Col span={12} className=" mb-3 text-end">
                  {monsterDetail?.crystallization_2 && (
                    <div className="font__M__plus font-medium">
                      {monsterDetail?.exploration_10}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </>
      ) : (
        <>
          <Row>
            <Col span={12} className="text-[16px]">
              <div className="mb-3 font__M__plus font-bold">
                {t('hashNFTDetail.ID')}
              </div>
              {monsterDetail?.main_seed && (
                <div className="mb-3 font__M__plus font-bold">
                  {t('hashNFTDetail.name')}
                </div>
              )}
              {monsterDetail?.name &&
                monsterDetail?.asset === 'HASH CHIP NFT' && (
                  <div className="mb-3 font__M__plus font-bold">
                    {t('hashNFTDetail.name')}
                  </div>
                )}

              {monsterDetail?.description && (
                <div className="mb-3 font__M__plus font-bold">
                  {t('hashNFTDetail.description')}
                </div>
              )}
            </Col>
            <Col span={12} className="text-end">
              <div className="mb-3 font__M__plus font-medium">
                <a
                  style={{ color: '#40A9FF' }}
                  href={`${configs.NETWORK.scan}token/${
                    monsterDetail?.contractAddress ??
                    // eslint-disable-next-line
                    (monsterDetail?.type_name == 'General Hash'
                      ? configs.ADDRESS_GENERAL
                      : configs.ADDRESS_GENESIS)
                  }/instance/${monsterDetail?.tokenId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {monsterDetail?.tokenId}
                </a>
              </div>
              {monsterDetail?.main_seed && (
                <div className="mb-3 font__M__plus font-medium">
                  {monsterDetail?.type_name} [{monsterDetail?.main_seed}]
                </div>
              )}
              {monsterDetail?.name &&
                monsterDetail?.asset === 'HASH CHIP NFT' && (
                  <div className="mb-3 font__M__plus font-medium">
                    {monsterDetail?.name}
                  </div>
                )}
              {monsterDetail?.description && (
                <div
                  className="mb-3 font__M__plus font-medium"
                  dangerouslySetInnerHTML={{
                    __html: monsterDetail.description,
                  }}
                ></div>
              )}
            </Col>
          </Row>
          {monsterDetail?.timesRegeneration !== undefined && (
            <Row>
              <Col span={12} className="text-[16px]">
                <div className="mb-3 font__M__plus font-bold">
                  {t('hashNFTDetail.regenerationCount')}
                </div>
              </Col>
              <Col span={12} className="text-end">
                <div className="mb-3 font__M__plus font-medium">
                  {monsterDetail?.type_name === 'Genesis Hash'
                    ? monsterDetail?.timesRegeneration + '/5'
                    : monsterDetail?.type_name === 'Hash Chip NFT'
                    ? monsterDetail?.timesRegeneration + '/1'
                    : monsterDetail?.timesRegeneration + '/3'}
                </div>
              </Col>
            </Row>
          )}
          {monsterDetail?.timesRegeneration === undefined && (
            <Row>
              <Col span={12} className="text-[16px]">
                <div className="mb-3 font__M__plus font-bold">
                  {t('hashNFTDetail.regenerationCount')}
                </div>
              </Col>
              <Col span={12} className="text-end">
                <div className="mb-3 font__M__plus font-medium">0/1</div>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={12} className="text-[16px]">
              {monsterDetail?.main_seed && (
                <div className="font__M__plus font-bold">
                  {t('hashNFTDetail.mainSeed')}
                </div>
              )}
            </Col>
            <Col span={12} className="text-end">
              {monsterDetail?.main_seed && (
                <div className="font__M__plus font-medium">
                  {monsterDetail?.main_seed}
                </div>
              )}
            </Col>
          </Row>
        </>
      )}
    </Card>
  )
}
