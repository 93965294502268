import { ArrowUpOutlined, FilterOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Dropdown, Pagination, Row, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import {
  ContractMonster,
  ifaceContractMarketplace,
  ifaceContractMonster,
} from '~/blockchain/contract'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import {
  checkTransactionConfirmation,
  getBalanceOAS,
  hexToNumberString,
  toWei,
} from '~/blockchain/provider'
import {
  MONSTER_SORT_USER,
  MONSTER_SORT_USER_JA,
  SCREEN_SIZE,
} from '~/common/constants'
import { useTranslation } from '~/common/hooks/useTranslation'
import SellModal from '~/components/Modals/SellModal'
import BodyProfile from '~/components/shared-components/BodyProfile'
import { MonstersCard } from '~/components/shared-components/Card/MonstersCard'
import configs from '~/configs'
import { useMonster } from '~/views/redux/hooks/useMonster'
// import { useQueryState } from '~/common/hooks/useQueryState'
import TransactionCompletedSell from '~/components/Modals/TransactionCompletedSell'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import { NodataMonster } from '~/components/shared-components/NoData/NodataMonster'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useSDK } from '@metamask/sdk-react'
import { useMediaQuery } from 'react-responsive'
import useDebounce from '~/common/hooks/useDebounce'

const FilterMonster = React.lazy(() =>
  import('~/views/app/Home/components/FilterMonster'),
)

export const AssetsMonsters = () => {
  const { sdk } = useSDK()
  const { t } = useTranslation()
  const [sort, setSort] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.sort ||
      MONSTER_SORT_USER[0],
  )
  const [typeSort, setTypeSort] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.typeSort || '-1',
  )
  const {
    data: { user },
  } = useTheme()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoadingModal, setLoadingModal] = useState(false)
  const [nftActive, setNftActive] = useState()
  const [txHash, setTxHash] = useState()
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [openFilterMonster, setOpenFilterMonster] = useState(false)
  // monster
  const [rankTagFilter, setRankTagFilter] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.rankTagFilter || [],
  )
  const [mainSeedFilter, setMainSeedFilter] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.mainSeedFilter || [],
  )
  const [subSeedFilter, setSubSeedFilter] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.subSeedFilter || [],
  )
  const [basicParameter, setBasicParameter] = useState(() => {
    const storedData = localStorage.getItem('monsterMyAssets')
    return storedData
      ? JSON.parse(storedData)?.basicParameter
      : {
          health: [],
          strength: [],
          intelligent: [],
          dexterity: [],
          agility: [],
          vitality: [],
        }
  })

  const [growthPotential, setGrowthPotential] = useState(() => {
    const storedData = localStorage.getItem('monsterMyAssets')
    return storedData
      ? JSON.parse(storedData)?.growthPotential
      : {
          hp: [],
          str: [],
          int: [],
          dex: [],
          agi: [],
          vit: [],
        }
  })
  const [growthType, setGrowthType] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.growthType || [],
  )

  const [valueLifeSpan, setValueLifeSpan] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.valueLifeSpan || [
      0, 5000,
    ],
  )

  const [terrainCompatibility, setTerrainCompatibility] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.terrainCompatibility ||
      [],
  )

  const [skillType, setSkillType] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.skillType || [],
  )
  const [specialtyElement, setSpecialtyElement] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.specialtyElement || [],
  )

  const [personality, setPersonality] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.personality || [],
  )
  const [innateTraitsByEffect, setInnateTraitsByEffect] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.innateTraitsByEffect ||
      [],
  )
  const [acquiredTraitsByEffect, setAcquiredTraitsByEffect] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))
      ?.acquiredTraitsByEffect || [],
  )
  const [pageSize, setPageSize] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.pageSize || 10,
  )
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem('monsterMyAssets'))?.page || 1,
  )
  const {
    actions,
    data: { allMonsterUser, totalMonsterUser, isLoading },
  } = useMonster()
  // const { page, pageSize, setPage, setPageSize } = useQueryState()
  const [balanceOAS, setBalanceOAS] = useState(0)

  const showModalSuccess = () => {
    setIsModalSuccessOpen(true)
  }

  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSuccess = () => {
    setIsModalSuccessOpen(false)
  }
  const showDrawer = () => {
    setOpenFilterMonster(true)
  }

  const onClose = () => {
    setOpenFilterMonster(false)
  }
  const handleSell = async (tokenID, price) => {
    try {
      setLoadingModal(true)
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let allowance = await ContractMonster.getApproved(tokenID)
        if (
          allowance.toLowerCase() === configs.ADDRESS_MARKETPLACE.toLowerCase()
        ) {
          sell(tokenID, price)
        } else {
          let data = {
            from: providerOAS.accounts[0],
            data: ifaceContractMonster.encodeFunctionData('approve', [
              configs.ADDRESS_MARKETPLACE,
              tokenID,
            ]),
            to: configs.ADDRESS_MONSTER,
            nonce: nonce,
            gasLimit: 21000,
          }

          const signedTransaction = await providerOAS.request({
            method: 'eth_signTransaction',
            params: [data],
          })

          const transaction = await ethersWeb3Provider.sendTransaction(
            String(signedTransaction),
          )
          checkTransactionConfirmation(transaction?.hash).then((r) => {
            if (r === 'confirmed') {
              // eslint-disable-next-line
              console.log('Approve:', r, transaction?.hash)
              sell(tokenID, price)
            }
          })
        }
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        let allowance = await ContractMonster.getApproved(tokenID)
        if (
          allowance.toLowerCase() === configs.ADDRESS_MARKETPLACE.toLowerCase()
        ) {
          sell(tokenID, price)
        } else {
          let nonce = await sdk.getProvider().request({
            method: 'eth_getTransactionCount',
            params: [user.address_wallet, 'latest'],
          })
          sdk
            .getProvider()
            .request({
              method: 'eth_sendTransaction',
              params: [
                {
                  from: user.address_wallet,
                  data: ifaceContractMonster.encodeFunctionData('approve', [
                    configs.ADDRESS_MARKETPLACE,
                    tokenID,
                  ]),
                  to: configs.ADDRESS_MONSTER,
                  nonce,
                },
              ],
            })
            .then((txHash) => {
              checkTransactionConfirmation(txHash).then((r) => {
                if (r === 'confirmed') {
                  // eslint-disable-next-line
                  console.log('Approve:', r, txHash)
                  sell(tokenID, price)
                }
              })
            })
            .catch((error) => {
              setLoadingModal(false)
              // eslint-disable-next-line
              console.error(error)
            })
        }
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const sell = async (tokenID, price) => {
    try {
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'createMarketItemSale',
            [configs.ADDRESS_MONSTER, tokenID, toWei(price), 1],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccess()
          }
        })
      } else {
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'createMarketItemSale',
                  [configs.ADDRESS_MONSTER, tokenID, toWei(price), 1],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccess()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      setLoadingModal(false)
    }
  }

  const showModalSell = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setLoadingModal(false)
  }

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }
  const debouncedPage = useDebounce(page, 1000)
  const debouncedPageSize = useDebounce(pageSize, 1000)
  const debouncedSort = useDebounce(sort, 1000)
  const debouncedTypeSort = useDebounce(typeSort, 1000)
  const debouncedRankTagFilter = useDebounce(rankTagFilter, 1000)
  const debouncedMainSeedFilter = useDebounce(mainSeedFilter, 1000)
  const debouncedSubSeedFilter = useDebounce(subSeedFilter, 1000)
  const debouncedBasicParameter = useDebounce(basicParameter, 1000)
  const debouncedGrowthPotential = useDebounce(growthPotential, 1000)
  const debouncedGrowthType = useDebounce(growthType, 1000)
  const debouncedValueLifeSpan = useDebounce(valueLifeSpan, 1000)
  const debouncedTerrainCompatibility = useDebounce(terrainCompatibility, 1000)
  const debouncedPersonality = useDebounce(personality, 1000)
  const debouncedInnateTraitsByEffect = useDebounce(innateTraitsByEffect, 1000)
  const debouncedAcquiredTraitsByEffect = useDebounce(
    acquiredTraitsByEffect,
    1000,
  )
  const debouncedSkillType = useDebounce(skillType, 1000)
  const debouncedSpecialtyElement = useDebounce(specialtyElement, 1000)
  //Refresh data
  const refreshData = useCallback(() => {
    const params = {
      page,
      pageSize: pageSize,
      wallet_address: user.address_wallet,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      monster_rank: rankTagFilter?.map((data) => Number(data)),
      main_seed: mainSeedFilter,
      sub_seed: subSeedFilter,
      alter_basic_p: basicParameter,
      growth_potential: growthPotential,
      growth_type: growthType,
      life_span: {
        from: valueLifeSpan[0],
        to: valueLifeSpan[1],
      },
      terrain_compatibility: terrainCompatibility?.map((data) => data?.name),
      monster_personality_id: personality,
      innate_trait_id_list: innateTraitsByEffect,
      monster_acquired_traits: acquiredTraitsByEffect,
      skill_type: skillType?.map((data) => data?.name),
      element_type: specialtyElement?.map((data) => data?.name),
    }
    actions.getAllMonsterUser(params)
  }, [
    page,
    pageSize,
    sort,
    typeSort,
    rankTagFilter,
    mainSeedFilter,
    subSeedFilter,
    basicParameter,
    growthPotential,
    growthType,
    valueLifeSpan,
    terrainCompatibility,
    personality,
    innateTraitsByEffect,
    acquiredTraitsByEffect,
    skillType,
    specialtyElement,
  ])

  useEffect(
    () => {
      const abortController = new AbortController()
      if (user) {
        refreshData()
      }
      return () => {
        abortController.abort()
      }
    },

    [
      txHash,
      debouncedPage,
      debouncedPageSize,
      debouncedSort,
      debouncedTypeSort,
      debouncedRankTagFilter,
      debouncedMainSeedFilter,
      debouncedSubSeedFilter,
      debouncedBasicParameter,
      debouncedGrowthPotential,
      debouncedGrowthType,
      debouncedValueLifeSpan,
      debouncedTerrainCompatibility,
      debouncedPersonality,
      debouncedInnateTraitsByEffect,
      debouncedAcquiredTraitsByEffect,
      debouncedSkillType,
      debouncedSpecialtyElement,
    ], // Only call effect if debounced search term changes
  )

  useEffect(() => {
    if (user) {
      loadBalanceOAS()
    }
  }, [txHash])
  useEffect(() => {
    if (page > 1) {
      onChangePage(1)
    }
  }, [
    txHash,
    pageSize,
    sort,
    typeSort,
    rankTagFilter,
    mainSeedFilter,
    subSeedFilter,
    basicParameter,
    growthPotential,
    growthType,
    valueLifeSpan,
    terrainCompatibility,
    personality,
    innateTraitsByEffect,
    acquiredTraitsByEffect,
    skillType,
    specialtyElement,
  ])
  const pageFilter = JSON.parse(localStorage.getItem('monsterMyAssets'))?.page
  useEffect(() => {
    if (pageFilter > 1) {
      setPage(pageFilter)
    }
  }, [pageFilter])
  const handleChangeSort = (e) => {
    setSort(MONSTER_SORT_USER[e.key])
  }

  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }

  const onChangePage = (page) => {
    setPage(page)
  }
  const onChangePageSize = (_, size) => {
    setPageSize(size)
  }
  return (
    <BodyProfile>
      <Row className={`${isMobile ? '' : 'mx-auto h-[100vh]'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterMonster
                sort={sort}
                typeSort={typeSort}
                page={page}
                pageSize={pageSize}
                className="filter_profile"
                rankTagFilter={rankTagFilter}
                mainSeedFilter={mainSeedFilter}
                subSeedFilter={subSeedFilter}
                basicParameter={basicParameter}
                growthPotential={growthPotential}
                growthType={growthType}
                valueLifeSpan={valueLifeSpan}
                terrainCompatibility={terrainCompatibility}
                skillType={skillType}
                specialtyElement={specialtyElement}
                personality={personality}
                innateTraitsByEffect={innateTraitsByEffect}
                acquiredTraitsByEffect={acquiredTraitsByEffect}
                setRankTagFilter={setRankTagFilter}
                setMainSeedFilter={setMainSeedFilter}
                setSubSeedFilter={setSubSeedFilter}
                setBasicParameter={setBasicParameter}
                setGrowthPotential={setGrowthPotential}
                setGrowthType={setGrowthType}
                setValueLifeSpan={setValueLifeSpan}
                setTerrainCompatibility={setTerrainCompatibility}
                setSkillType={setSkillType}
                setSpecialtyElement={setSpecialtyElement}
                setPersonality={setPersonality}
                setInnateTraitsByEffect={setInnateTraitsByEffect}
                setAcquiredTraitsByEffect={setAcquiredTraitsByEffect}
                classCustom="filter__profile"
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="mx-auto z-10 pl-0  relative md:sticky top-0 sm:top-[104px] pt-[1.5rem]  pb-12">
            <div className="text-[24px] mb-4 uppercase">
              {t('profile.menu.monsters')}
            </div>

            <div className="flex justify-between flex-wrap mb-4">
              <div className="text-[24px]">
                {t('countMonster', {
                  total: totalMonsterUser,
                })}
                {totalMonsterUser > 1 ? t('more') : ''}
              </div>
              {isMobile ? (
                <>
                  {' '}
                  <div className="flex items-center text-[16px] gap-2">
                    <Dropdown
                      className="flex items-center cursor-pointer sort__value"
                      menu={{
                        items:
                          user?.player_language === 'en'
                            ? MONSTER_SORT_USER
                            : MONSTER_SORT_USER_JA,
                        selectable: true,
                        onClick: handleChangeSort,
                      }}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                        <div className="title">
                          {' '}
                          {t(`common.filter.sort.${sort?.valueLanguage}`)}
                        </div>
                      </Button>
                    </Dropdown>

                    <Button
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                      onClick={() => {
                        handleChangeTypeSort()
                      }}
                    >
                      <ArrowUpOutlined
                        style={{ fontSize: 18 }}
                        rotate={typeSort === '1' ? 0 : 180}
                      />
                    </Button>
                    <div>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                      >
                        <FilterOutlined />
                        {t('filter')}
                      </Button>
                      <Drawer
                        title={t('filter')}
                        width={330}
                        placement="left"
                        onClose={onClose}
                        open={openFilterMonster}
                        extra={
                          <div
                            className="text-[16px] text-[#FAAD14] cursor-pointer"
                            onClick={() => {
                              setRankTagFilter([])
                              setMainSeedFilter([])
                              setSubSeedFilter([])
                              setBasicParameter({
                                HP: [],
                                STR: [],
                                INT: [],
                                DEX: [],
                                AGI: [],
                                VIT: [],
                              })
                              setGrowthPotential({
                                HP: [],
                                STR: [],
                                INT: [],
                                DEX: [],
                                AGI: [],
                                VIT: [],
                              })
                              setGrowthType([])
                              setValueLifeSpan([0, 0])
                              setTerrainCompatibility([])
                              setSkillType([])
                              setSpecialtyElement([])
                              setPersonality([])
                              setInnateTraitsByEffect([])
                              setAcquiredTraitsByEffect([])
                            }}
                          >
                            {t('clearAll')}
                          </div>
                        }
                      >
                        <div className="flex w-full h-full">
                          <FilterMonster
                            sort={sort}
                            typeSort={typeSort}
                            page={page}
                            pageSize={pageSize}
                            className="filter_profile"
                            rankTagFilter={rankTagFilter}
                            mainSeedFilter={mainSeedFilter}
                            subSeedFilter={subSeedFilter}
                            basicParameter={basicParameter}
                            growthPotential={growthPotential}
                            growthType={growthType}
                            valueLifeSpan={valueLifeSpan}
                            terrainCompatibility={terrainCompatibility}
                            skillType={skillType}
                            specialtyElement={specialtyElement}
                            personality={personality}
                            innateTraitsByEffect={innateTraitsByEffect}
                            acquiredTraitsByEffect={acquiredTraitsByEffect}
                            setRankTagFilter={setRankTagFilter}
                            setMainSeedFilter={setMainSeedFilter}
                            setSubSeedFilter={setSubSeedFilter}
                            setBasicParameter={setBasicParameter}
                            setGrowthPotential={setGrowthPotential}
                            setGrowthType={setGrowthType}
                            setValueLifeSpan={setValueLifeSpan}
                            setTerrainCompatibility={setTerrainCompatibility}
                            setSkillType={setSkillType}
                            setSpecialtyElement={setSpecialtyElement}
                            setPersonality={setPersonality}
                            setInnateTraitsByEffect={setInnateTraitsByEffect}
                            setAcquiredTraitsByEffect={
                              setAcquiredTraitsByEffect
                            }
                            classCustom="filter__profile"
                          />
                        </div>
                      </Drawer>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  <div className="flex items-center text-[16px] gap-2">
                    <div> {t('sortBy')}</div>

                    <Dropdown
                      className="flex items-center cursor-pointer ml-3  sort__value"
                      menu={{
                        items:
                          user?.player_language === 'en'
                            ? MONSTER_SORT_USER
                            : MONSTER_SORT_USER_JA,
                        selectable: true,
                        onClick: handleChangeSort,
                      }}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                        <div className="title">
                          {' '}
                          {t(`common.filter.sort.${sort?.valueLanguage}`)}
                        </div>
                      </Button>
                    </Dropdown>

                    <Button
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                      onClick={() => {
                        handleChangeTypeSort()
                      }}
                    >
                      <ArrowUpOutlined
                        style={{ fontSize: 18 }}
                        rotate={typeSort === '1' ? 0 : 180}
                      />
                    </Button>
                  </div>
                </>
              )}
            </div>

            <Spin spinning={isLoading}>
              <div className="flex flex-wrap justify-center gap-4">
                {allMonsterUser?.map((data, i) => (
                  <MonstersCard
                    key={i?.toString()}
                    data={data}
                    t={t}
                    button={{
                      title: 'Sell',
                      functionButton: () => {
                        setNftActive(data)
                        showModalSell()
                      },
                    }}
                  />
                ))}
              </div>
            </Spin>
            {!isLoading && totalMonsterUser === 0 && <NodataMonster />}

            {totalMonsterUser > 0 && (
              <div className="pagination__common">
                <Pagination
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                  className="mt-5"
                  size="small"
                  locale={{ jump_to: 'Go to', page: '' }}
                  total={totalMonsterUser}
                  // defaultCurrent={page}
                  pageSize={pageSize}
                  current={page}
                  showSizeChanger
                  showQuickJumper
                  showTitle
                />
              </div>
            )}
          </div>

          <SellModal
            action={{
              isModalOpen: isModalOpen,
              handleOk: handleSell,
              handleCancel: handleCancel,
            }}
            t={t}
            tokenID={nftActive?.monster_nft_id}
            loadingModal={isLoadingModal}
            name={'Monster'}
            balance={balanceOAS}
          />
          <TransactionCompletedSell
            action={{
              isModalOpen: isModalSuccessOpen,
              handleOk: handleShare,
              handleCancel: handleCancelSuccess,
            }}
            t={t}
            name={`Monster(#${nftActive?.monster_nft_id})`}
            store={'Marketplace'}
            txHash={txHash}
          />
        </Col>
      </Row>
    </BodyProfile>
  )
}
export default AssetsMonsters
