import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Input,
  Pagination,
  Row,
  Spin,
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyCommon from '~/components/shared-components/BodyCommon'
import {
  MONSTER_SORT_MKP,
  MONSTER_SORT_MKP_JA,
  SCREEN_SIZE,
} from '~/common/constants'
import {
  ArrowUpOutlined,
  FilterOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { MonstersCard } from '~/components/shared-components/Card/MonstersCard'
import { useMonster } from '~/views/redux/hooks/useMonster'
import { useQueryState } from '~/common/hooks/useQueryState'
import { useCommon } from '~/views/redux/hooks/useCommon'
import CheckoutModal from '~/components/Modals/Checkout'
import { useAccount } from '~/common/hooks/useAccount'
import {
  checkTransactionConfirmation,
  getBalanceOAS,
  hexToNumberString,
  numberToHex,
  toWei,
} from '~/blockchain/provider'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import configs from '~/configs'
import { ifaceContractMarketplace } from '~/blockchain/contract'
import TransactionCompletedMarketplace from '~/components/Modals/TransactionCompletedMarketplace'
import CancelSell from '~/components/Modals/CancelSell'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import { NoData } from '~/components/shared-components/NoData'
import SelectWallet from '~/components/Modals/SelectWallet'
import { scrollToTop } from '~/helpers/common'
import { useSDK } from '@metamask/sdk-react'
import { useMediaQuery } from 'react-responsive'
import useDebounce from '~/common/hooks/useDebounce'
const FilterMonster = React.lazy(() => import('./components/FilterMonster'))

export const Home = () => {
  const { sdk } = useSDK()

  // eslint-disable-next-line
  const [isModalOpenBuy, setIsModalOpenBuy] = useState(false)
  const [isModalOpenCancel, setIsModalOpenCancel] = useState(false)
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [nftActive, setNftActive] = useState(0)
  const [txHash, setTxHash] = useState()
  const { user } = useAccount()
  const [loadingModal, setLoadingModal] = useState(false)
  const [isModalSuccessOpenBuy, setIsModalSuccessOpenBuy] = useState(false)
  const [isModalSuccessOpenCancelSell, setIsModalSuccessOpenCancelSell] =
    useState(false)
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpenBuy(true)
  }

  const showModalCancelSell = () => {
    setIsModalOpenCancel(true)
  }

  const handleCancelBuy = () => {
    setIsModalOpenBuy(false)
  }

  const handleCancel = () => {
    setIsModalOpenCancel(false)
  }

  const showModalSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(true)
  }

  const handleShareCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const handleCancelSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const showModalSuccessBuy = () => {
    setIsModalSuccessOpenBuy(true)
  }

  const handleShareBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleCancelSuccessBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  const { t } = useTranslation()
  const [sort, setSort] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.sort ||
      MONSTER_SORT_MKP[0],
  )
  const [typeSort, setTypeSort] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.typeSort || '-1',
  )
  const { keyword, setKeyword } = useQueryState()
  const {
    actions: actionsTema,
    data: { allTEMAs },
  } = useCommon()
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [openFilterMonster, setOpenFilterMonster] = useState(false)
  const [form] = Form.useForm()
  const [rankTagFilter, setRankTagFilter] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.rankTagFilter ||
      [],
  )
  const [mainSeedFilter, setMainSeedFilter] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.mainSeedFilter ||
      [],
  )
  const [subSeedFilter, setSubSeedFilter] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.subSeedFilter ||
      [],
  )
  const [basicParameter, setBasicParameter] = useState(() => {
    const storedData = localStorage.getItem('monstersMarketplace')
    return storedData
      ? JSON.parse(storedData)?.basicParameter
      : {
          health: [],
          strength: [],
          intelligent: [],
          dexterity: [],
          agility: [],
          vitality: [],
        }
  })

  const [growthPotential, setGrowthPotential] = useState(() => {
    const storedData = localStorage.getItem('monstersMarketplace')
    return storedData
      ? JSON.parse(storedData)?.growthPotential
      : {
          hp: [],
          str: [],
          int: [],
          dex: [],
          agi: [],
          vit: [],
        }
  })
  const [growthType, setGrowthType] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.growthType || [],
  )

  const [valueLifeSpan, setValueLifeSpan] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.valueLifeSpan || [
      0, 5000,
    ],
  )

  const [terrainCompatibility, setTerrainCompatibility] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))
      ?.terrainCompatibility || [],
  )

  const [skillType, setSkillType] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.skillType || [],
  )
  const [specialtyElement, setSpecialtyElement] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.specialtyElement ||
      [],
  )

  const [personality, setPersonality] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.personality || [],
  )
  const [innateTraitsByEffect, setInnateTraitsByEffect] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))
      ?.innateTraitsByEffect || [],
  )
  const [acquiredTraitsByEffect, setAcquiredTraitsByEffect] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))
      ?.acquiredTraitsByEffect || [],
  )
  const [pageSize, setPageSize] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.pageSize || 10,
  )
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem('monstersMarketplace'))?.page || 1,
  )

  const {
    actions,
    data: { allMonsters, isLoading, total },
  } = useMonster()

  //Refresh data
  const refreshData = useCallback(() => {
    const params = {
      page,
      pageSize: pageSize,
      search: keyword,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      monster_rank: rankTagFilter?.map((data) => Number(data)),
      main_seed: mainSeedFilter,
      sub_seed: subSeedFilter,
      alter_basic_p: basicParameter,
      growth_potential: growthPotential,
      growth_type: growthType,
      life_span: {
        from: valueLifeSpan[0],
        to: valueLifeSpan[1],
      },
      terrain_compatibility: terrainCompatibility?.map((data) => data?.name),
      monster_personality_id: personality,
      innate_trait_id_list: innateTraitsByEffect,
      monster_acquired_traits: acquiredTraitsByEffect,
      skill_type: skillType?.map((data) => data?.name),
      element_type: specialtyElement?.map((data) => data?.name),
    }
    actions.getAllMonster(params, () => {
      scrollToTop()
    })
  }, [
    page,
    pageSize,
    sort,
    typeSort,
    keyword,
    rankTagFilter,
    mainSeedFilter,
    subSeedFilter,
    basicParameter,
    growthPotential,
    growthType,
    valueLifeSpan,
    terrainCompatibility,
    personality,
    innateTraitsByEffect,
    acquiredTraitsByEffect,
    skillType,
    specialtyElement,
  ])

  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }

  const handleSubmit = (values) => {
    setKeyword(values?.search ? values?.search?.trim() : '')
  }

  const handleChangeSort = (e) => {
    setSort(MONSTER_SORT_MKP[e.key])
  }

  const onChangePage = (page) => {
    setPage(page)
  }
  const onChangePageSize = (current, size) => {
    setPageSize(size)
  }

  useEffect(() => {
    if (user && user !== null) {
      loadBalanceOAS()
    }
  }, [user, txHash])

  const debouncedPage = useDebounce(page, 1000)
  const debouncedPageSize = useDebounce(pageSize, 1000)
  const debouncedKeyword = useDebounce(keyword, 1000)
  const debouncedSort = useDebounce(sort, 1000)
  const debouncedTypeSort = useDebounce(typeSort, 1000)
  const debouncedRankTagFilter = useDebounce(rankTagFilter, 1000)
  const debouncedMainSeedFilter = useDebounce(mainSeedFilter, 1000)
  const debouncedSubSeedFilter = useDebounce(subSeedFilter, 1000)
  const debouncedBasicParameter = useDebounce(basicParameter, 1000)
  const debouncedGrowthPotential = useDebounce(growthPotential, 1000)
  const debouncedGrowthType = useDebounce(growthType, 1000)
  const debouncedValueLifeSpan = useDebounce(valueLifeSpan, 1000)
  const debouncedTerrainCompatibility = useDebounce(terrainCompatibility, 1000)
  const debouncedPersonality = useDebounce(personality, 1000)
  const debouncedInnateTraitsByEffect = useDebounce(innateTraitsByEffect, 1000)
  const debouncedAcquiredTraitsByEffect = useDebounce(
    acquiredTraitsByEffect,
    1000,
  )
  const debouncedSkillType = useDebounce(skillType, 1000)
  const debouncedSpecialtyElement = useDebounce(specialtyElement, 1000)

  useEffect(
    () => {
      const abortController = new AbortController()
      refreshData()
      return () => {
        abortController.abort()
      }
    },

    [
      txHash,
      debouncedPage,
      debouncedPageSize,
      debouncedKeyword,
      debouncedSort,
      debouncedTypeSort,
      debouncedRankTagFilter,
      debouncedMainSeedFilter,
      debouncedSubSeedFilter,
      debouncedBasicParameter,
      debouncedGrowthPotential,
      debouncedGrowthType,
      debouncedValueLifeSpan,
      debouncedTerrainCompatibility,
      debouncedPersonality,
      debouncedInnateTraitsByEffect,
      debouncedAcquiredTraitsByEffect,
      debouncedSkillType,
      debouncedSpecialtyElement,
    ], // Only call effect if debounced search term changes
  )

  useEffect(() => {
    actionsTema.getTema()
  }, [txHash])

  useEffect(() => {
    if (page > 1) {
      onChangePage(1)
    }
  }, [
    txHash,
    pageSize,
    keyword,
    sort,
    typeSort,
    rankTagFilter,
    mainSeedFilter,
    subSeedFilter,
    basicParameter,
    growthPotential,
    growthType,
    valueLifeSpan,
    terrainCompatibility,
    personality,
    innateTraitsByEffect,
    acquiredTraitsByEffect,
    skillType,
    specialtyElement,
  ])
  const pageFilter = JSON.parse(
    localStorage.getItem('monstersMarketplace'),
  )?.page
  useEffect(() => {
    if (pageFilter > 1) {
      setPage(pageFilter)
    }
  }, [pageFilter])
  const callCheckBuyMonsterAPI = async (txHash) => {
    try {
      const response = await fetch(
        `https://blockchain-api-staging.remonster.world/api/monster/check-buy_monster?txHash=${txHash}`,
        {
          method: 'GET',
        },
      )

      if (response.ok) {
        const result = await response.json()
        console.log('API Response:', result)
        // Handle the API response as needed
      } else {
        console.error('API call failed:', response.statusText)
      }
    } catch (apiError) {
      console.error('Error calling check-buy_monster API:', apiError)
    }
  }
  const handleBuy = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
            nftActive?.orderId,
          ]),
          to: configs.ADDRESS_MARKETPLACE,
          value: numberToHex(toWei(nftActive?.price)),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancelBuy()
            setTxHash(transaction?.hash)
            showModalSuccessBuy()
            callCheckBuyMonsterAPI(transaction?.hash)
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
                  nftActive?.orderId,
                ]),
                to: configs.ADDRESS_MARKETPLACE,
                value: numberToHex(toWei(nftActive?.price)),
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancelBuy()
                setTxHash(txHash)
                showModalSuccessBuy()
                callCheckBuyMonsterAPI(txHash)
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [nftActive?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccessCancelSell()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [nftActive?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccessCancelSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }
  const showDrawer = () => {
    setOpenFilterMonster(true)
  }

  const onClose = () => {
    setOpenFilterMonster(false)
  }

  return (
    <BodyCommon>
      <Row className={`${isMobile ? '' : 'container__common mx-auto h-full'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterMonster
                sort={sort}
                setSort={setSort}
                typeSort={typeSort}
                page={page}
                pageSize={pageSize}
                rankTagFilter={rankTagFilter}
                mainSeedFilter={mainSeedFilter}
                subSeedFilter={subSeedFilter}
                basicParameter={basicParameter}
                growthPotential={growthPotential}
                growthType={growthType}
                valueLifeSpan={valueLifeSpan}
                terrainCompatibility={terrainCompatibility}
                skillType={skillType}
                specialtyElement={specialtyElement}
                personality={personality}
                innateTraitsByEffect={innateTraitsByEffect}
                acquiredTraitsByEffect={acquiredTraitsByEffect}
                setRankTagFilter={setRankTagFilter}
                setMainSeedFilter={setMainSeedFilter}
                setSubSeedFilter={setSubSeedFilter}
                setBasicParameter={setBasicParameter}
                setGrowthPotential={setGrowthPotential}
                setGrowthType={setGrowthType}
                setValueLifeSpan={setValueLifeSpan}
                setTerrainCompatibility={setTerrainCompatibility}
                setSkillType={setSkillType}
                setSpecialtyElement={setSpecialtyElement}
                setPersonality={setPersonality}
                setInnateTraitsByEffect={setInnateTraitsByEffect}
                setAcquiredTraitsByEffect={setAcquiredTraitsByEffect}
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="z-10 pl-0 sm:pl-[15px] relative sm:sticky top-0 mt-0 sm:mt-[104px] pb-12">
            <div className="pt-0" style={{ marginTop: '100px' }}>
              <div className="flex mt-5 flex-col">
                <Row>
                  <Col xl={16} md={16} sm={24} xs={24}>
                    <Form
                      layout="vertical"
                      onFinish={handleSubmit}
                      form={form}
                      translate="yes"
                      autoComplete="off"
                      size="middle"
                      validateTrigger={['onBlur', 'onChange']}
                    >
                      <Row className="flex justify-between">
                        <Col span={24}>
                          <div
                            className={`${
                              isMobile
                                ? 'text-[24px] ml-[11px] font-normal'
                                : 'text-[24px] font-normal'
                            }`}
                            style={{ color: '#FFFFFF' }}
                          >
                            {t('countMonster', {
                              total: total,
                            })}
                            {total > 1 ? t('more') : ''}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col xl={8} md={8} sm={24} xs={24}>
                    {isMobile ? (
                      <>
                        {' '}
                        <div className="flex items-center text-[16px] gap-2 justify-normal sm:justify-end">
                          <Dropdown
                            className="flex items-center cursor-pointer ml-3  sort__value"
                            menu={{
                              items:
                                user?.player_language === 'ja'
                                  ? MONSTER_SORT_MKP_JA
                                  : MONSTER_SORT_MKP,
                              selectable: true,
                              onClick: handleChangeSort,
                            }}
                            placement="bottomRight"
                            trigger={['click']}
                          >
                            <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                              <div className="title">
                                {t(`common.filter.sort.${sort?.valueLanguage}`)}
                              </div>
                            </Button>
                          </Dropdown>

                          <Button
                            className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                            onClick={() => {
                              handleChangeTypeSort()
                            }}
                          >
                            <ArrowUpOutlined
                              style={{ fontSize: 18 }}
                              rotate={typeSort === '1' ? 0 : 180}
                            />
                          </Button>
                          <div>
                            <Button
                              type="primary"
                              onClick={showDrawer}
                              className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                            >
                              <FilterOutlined />
                              {t('filter')}
                            </Button>
                            <Drawer
                              title={t('filter')}
                              width={330}
                              placement="left"
                              onClose={onClose}
                              open={openFilterMonster}
                              extra={
                                <div
                                  className="text-[16px] text-[#FAAD14] cursor-pointer"
                                  onClick={() => {
                                    setRankTagFilter([])
                                    setMainSeedFilter([])
                                    setSubSeedFilter([])
                                    setBasicParameter({
                                      HP: [],
                                      STR: [],
                                      INT: [],
                                      DEX: [],
                                      AGI: [],
                                      VIT: [],
                                    })
                                    setGrowthPotential({
                                      HP: [],
                                      STR: [],
                                      INT: [],
                                      DEX: [],
                                      AGI: [],
                                      VIT: [],
                                    })
                                    setGrowthType([])
                                    setValueLifeSpan([0, 0])
                                    setTerrainCompatibility([])
                                    setSkillType([])
                                    setSpecialtyElement([])
                                    setPersonality([])
                                    setInnateTraitsByEffect([])
                                    setAcquiredTraitsByEffect([])
                                  }}
                                >
                                  {t('clearAll')}
                                </div>
                              }
                            >
                              <div className="flex w-full h-full">
                                <FilterMonster
                                  sort={sort}
                                  page={page}
                                  typeSort={typeSort}
                                  pageSize={pageSize}
                                  rankTagFilter={rankTagFilter}
                                  mainSeedFilter={mainSeedFilter}
                                  subSeedFilter={subSeedFilter}
                                  basicParameter={basicParameter}
                                  growthPotential={growthPotential}
                                  growthType={growthType}
                                  valueLifeSpan={valueLifeSpan}
                                  terrainCompatibility={terrainCompatibility}
                                  skillType={skillType}
                                  specialtyElement={specialtyElement}
                                  personality={personality}
                                  innateTraitsByEffect={innateTraitsByEffect}
                                  acquiredTraitsByEffect={
                                    acquiredTraitsByEffect
                                  }
                                  setRankTagFilter={setRankTagFilter}
                                  setMainSeedFilter={setMainSeedFilter}
                                  setSubSeedFilter={setSubSeedFilter}
                                  setBasicParameter={setBasicParameter}
                                  setGrowthPotential={setGrowthPotential}
                                  setGrowthType={setGrowthType}
                                  setValueLifeSpan={setValueLifeSpan}
                                  setTerrainCompatibility={
                                    setTerrainCompatibility
                                  }
                                  setSkillType={setSkillType}
                                  setSpecialtyElement={setSpecialtyElement}
                                  setPersonality={setPersonality}
                                  setInnateTraitsByEffect={
                                    setInnateTraitsByEffect
                                  }
                                  setAcquiredTraitsByEffect={
                                    setAcquiredTraitsByEffect
                                  }
                                  classCustom="filter__profile"
                                />
                              </div>
                            </Drawer>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {' '}
                        <div className="flex items-center text-[16px] gap-2 justify-normal sm:justify-end">
                          <div> {t('sortBy')}</div>
                          <Dropdown
                            className="flex items-center cursor-pointer ml-3  sort__value"
                            menu={{
                              items:
                                user?.player_language === 'ja'
                                  ? MONSTER_SORT_MKP_JA
                                  : MONSTER_SORT_MKP,
                              selectable: true,
                              onClick: handleChangeSort,
                            }}
                            placement="bottomRight"
                            trigger={['click']}
                          >
                            <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                              <div className="title">
                                {t(`common.filter.sort.${sort?.valueLanguage}`)}
                              </div>
                            </Button>
                          </Dropdown>

                          <Button
                            className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                            onClick={() => {
                              handleChangeTypeSort()
                            }}
                          >
                            <ArrowUpOutlined
                              style={{ fontSize: 18 }}
                              rotate={typeSort === '1' ? 0 : 180}
                            />
                          </Button>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </div>

              {/* Monster */}
              <Spin className="min-h-[350px]" spinning={isLoading}>
                <div className="flex flex-wrap justify-center gap-4 mt-5">
                  {allMonsters?.map((data, i) => (
                    <MonstersCard
                      key={i?.toString()}
                      data={data}
                      tema={allTEMAs}
                      button={{
                        title:
                          data?.seller?.toLowerCase() ===
                          user?.address_wallet?.toLowerCase()
                            ? 'Cancel'
                            : 'Buy',
                        functionButton: () => {
                          setNftActive(data)
                          if (
                            data?.seller?.toLowerCase() ===
                            user?.address_wallet?.toLowerCase()
                          ) {
                            showModalCancelSell()
                          } else {
                            showModalCheckout()
                          }
                        },
                      }}
                    />
                  ))}
                </div>
              </Spin>

              {!isLoading && total === 0 && <NoData />}
              {total > 0 && (
                <div className="pagination__common">
                  <Pagination
                    onChange={onChangePage}
                    onShowSizeChange={onChangePageSize}
                    className="mt-5"
                    size="small"
                    locale={{ jump_to: 'Go to', page: '' }}
                    total={total}
                    current={page}
                    pageSize={pageSize}
                    showSizeChanger
                    showQuickJumper
                    showTitle
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <CheckoutModal
        action={{
          isModalOpen: isModalOpenBuy,
          handleOk: handleBuy,
          handleCancel: handleCancelBuy,
        }}
        t={t}
        box={nftActive}
        loadingModal={loadingModal}
        tema={allTEMAs?.tema}
        balance={balanceOAS}
      />
      <CancelSell
        action={{
          isModalOpen: isModalOpenCancel,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        name={`Monster(#${nftActive?.monster_nft_id})`}
        loadingModal={loadingModal}
        // name={'Monster'}
        // tema={tema}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpenCancelSell,
          handleOk: handleShareCancelSell,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        name={`Monster(#${nftActive?.monster_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedMarketplace
        action={{
          isModalOpen: isModalSuccessOpenBuy,
          handleOk: handleShareBuy,
          handleCancel: handleCancelSuccessBuy,
        }}
        t={t}
        name={`Monster(#${nftActive?.monster_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />
    </BodyCommon>
  )
}

export default Home
