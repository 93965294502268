import React, { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyProfile from '~/components/shared-components/BodyProfile'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { useCommon } from '~/views/redux/hooks/useCommon'
import { fromWei, getAssetType, is1155 } from '~/blockchain/provider'
import {
  convertAddressToProperFormat,
  formatAddress,
  formatNumber,
  formatTransaction,
} from '~/helpers/common'
import { Button, Col, Drawer, Row, Spin } from 'antd'
import configs from '~/configs'
import { FilterHistory } from './filterHistory'
import { getNameItemByContract } from '~/helpers/ItemName'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useMediaQuery } from 'react-responsive'
import {
  DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2,
  SCREEN_SIZE,
} from '~/common/constants'
import { FilterOutlined } from '@ant-design/icons'
import moment from 'moment'
export const ProfileTradeHistory = () => {
  const { t } = useTranslation()
  const { page, pageSize, setPage, setPageSize } = useQueryState()
  const {
    data: { user },
  } = useTheme()
  const {
    actions: actionsCommon,
    data: { isLoading },
  } = useCommon()
  const [dataHistories, setDataHistories] = useState([])
  const [totalData, setTotalData] = useState(0)

  const [event, setEvent] = useState([])
  const [assetType, setAssetType] = useState([])
  const [date, setDate] = useState([])
  useEffect(() => {
    if (user) {
      let dataTemp = {
        address: user?.address_wallet,
        page: page,
        pageSize: pageSize,
        fromTime: isNaN(date[0]) ? undefined : date[0],
        toTime: isNaN(date[1]) ? undefined : date[1],
        event: event,
        addressContract: assetType,
      }
      actionsCommon.getHistories(dataTemp, async (res) => {
        let data = await Promise.all(
          res.data.map((temp, index) => {
            return {
              key: index,
              event:
                temp?.type === 'OrderCreated'
                  ? 'For Sale'
                  : temp?.type === 'OrderSuccessful'
                  ? 'Buy'
                  : undefined,
              assetType: getAssetType(temp?.asset_address),
              tokenID: {
                id: temp?.tokenId,
                address: temp?.asset_address,
              },
              time: temp?.timestamp,
              from: temp?.from,
              to: temp?.to,
              price: fromWei(temp?.priceInWei),
              txHash: temp?.transactionHash,
              created_at: temp?.timestamp,
              quantity: temp?.amount,
            }
          }),
        )
        setTotalData(res?.total)
        setDataHistories(data)
      })
    }
  }, [page, pageSize, date, event, assetType])
  const converTextEvent = (text) => {
    switch (text) {
      case 'For Sale':
        return '販売中'
      case 'Buy':
        return '購入'
      default:
        return ''
    }
  }
  const converTextassetType = (text) => {
    switch (text) {
      case 'Monster':
        return 'Monster'
      case 'Farm':
        return 'Farm'
      case 'Genesis Hash':
        return 'Genesis Hash'
      case 'General Hash':
        return 'General Hash'
      case 'Regeneration Hash':
        return '再生ハッシュ'
      case 'Enhance Item':
        return '強化アイテム'
      case 'Training Item':
        return '育成アイテム'
      case 'Fushion Item':
        return '融合アイテム'
      default:
        return '強化アイテム'
    }
  }
  const columns = [
    {
      title: t('profile.tradeHistory.event'),
      dataIndex: 'event',
      align: 'center',
      key: 'event',
      render: (data) => {
        return (
          <div>
            {user?.player_language === 'ja' ? converTextEvent(data) : data}
          </div>
        )
      },
    },
    {
      title: t('profile.tradeHistory.assetType'),
      dataIndex: 'assetType',
      align: 'center',
      key: 'assetType',
      render: (data) => {
        return (
          <div>
            {user?.player_language === 'ja' ? converTextassetType(data) : data}
          </div>
        )
      },
    },
    {
      title: t('profile.tradeHistory.tokenID'),
      dataIndex: 'tokenID',
      align: 'center',
      key: 'tokenID',
      render: (data) => {
        // if (is1155(data?.address)) {
        //   return getNameItemByContract(data?.address, data?.id)?.name
        // } else {
        //   const link = `${configs.NETWORK.scan}token/${data?.address}/instance/${data?.id}`
        //   return (
        //     <a
        //       style={{ color: '#40A9FF' }}
        //       href={link}
        //       target="_blank"
        //       rel="noopener noreferrer"
        //     >
        //       {`#${data?.id}`}
        //     </a>
        //   )
        // }
        const link = `${configs.NETWORK.scan}token/${data?.address}/instance/${data?.id}`
        return (
          <a
            style={{ color: '#40A9FF' }}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`#${data?.id}`}
          </a>
        )
      },
    },
    {
      title: t('profile.tradeHistory.time'),
      dataIndex: 'time',
      align: 'center',
      key: 'time',
      // render: (data) => {
      //   return moment(data * 1000)
      //     .tz('Asia/Tokyo')
      //     .format(DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2)
      // },
      render: (data) => {
        return moment(data * 1000)
          .utc()
          .format(DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2)
      },
    },
    {
      title: t('profile.tradeHistory.from'),
      dataIndex: 'from',
      align: 'center',
      key: 'from',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('profile.tradeHistory.to'),
      dataIndex: 'to',
      align: 'center',
      key: 'to',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('profile.tradeHistory.quantity'),
      dataIndex: 'quantity',
      align: 'center',
      key: 'quantity',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      title: t('profile.tradeHistory.price'),
      dataIndex: 'price',
      align: 'center',
      key: 'price',
      render: (data) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {formatNumber(data)} OAS
          </div>
        )
      },
    },
    {
      title: t('profile.tradeHistory.txHash'),
      dataIndex: 'txHash',
      align: 'center',
      key: 'txHash',
      render: (data) => {
        const link = `${configs.NETWORK.scan}tx/${data}`
        return (
          <a
            style={{ color: '#40A9FF' }}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatTransaction(data)}
          </a>
        )
      },
    },
  ]
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [openFilterHistory, setOpenFilterHistory] = useState(false)
  const showDrawer = () => {
    setOpenFilterHistory(true)
  }

  const onClose = () => {
    setOpenFilterHistory(false)
  }
  const clearAll = () => {
    setEvent([])
    setAssetType([])
    setDate([])
  }
  return (
    <BodyProfile>
      <Row className={`${isMobile ? '' : 'mx-auto h-[100vh] filter_profile'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterHistory
                event={event}
                assetType={assetType}
                date={date}
                setEvent={setEvent}
                setAssetType={setAssetType}
                setDate={setDate}
                classCustom="filter__profile"
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="mx-auto z-10 pl-0 sm:pl-[15px] relative md:sticky top-0 sm:top-[104px] pb-12 pt-[1.5rem]">
            {isMobile ? (
              <div
                className="text-[24px] mb-4"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div className="mt-[10px]" style={{ flex: 2 }}>
                  {t('profile.menu.tradeHistory')}
                </div>
                <div style={{ flex: 1, textAlign: 'right' }}>
                  <Button
                    type="primary"
                    onClick={showDrawer}
                    className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px] ml-[20px] mt-[10px]"
                  >
                    <FilterOutlined />
                    {t('filter')}
                  </Button>
                  <Drawer
                    title={t('filter')}
                    width={330}
                    placement="left"
                    onClose={onClose}
                    open={openFilterHistory}
                    extra={
                      <div
                        className="text-[16px] text-[#FAAD14] cursor-pointer"
                        onClick={() => {
                          clearAll()
                        }}
                      >
                        {t('clearAll')}
                      </div>
                    }
                  >
                    <div className="flex w-full h-full">
                      <FilterHistory
                        event={event}
                        assetType={assetType}
                        date={date}
                        setEvent={setEvent}
                        setAssetType={setAssetType}
                        setDate={setDate}
                        classCustom="filter__profile"
                      />
                    </div>
                  </Drawer>
                </div>
              </div>
            ) : (
              <div className="text-[24px] mb-4">
                {t('profile.menu.tradeHistory')}
              </div>
            )}

            <Datatable
              scroll={{ x: 1300 }}
              columns={columns}
              dataSource={dataHistories}
              loading={isLoading}
              metadata={{
                perPage: pageSize,
                setPerPage: setPageSize,
                page,
                setPage,
                total: totalData === 0 ? 1 : totalData,
              }}
              showPagination={true}
            />
          </div>
        </Col>
      </Row>
    </BodyProfile>
  )
}
export default ProfileTradeHistory
